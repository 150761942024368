<script>
  import router from './index.js';

  let component;

  router.pageChanged(({ id: newId }) => {
    //e.preventDefault();
    const section = document.getElementById(newId);
    section && section.scrollIntoView({behavior: 'smooth'});
  });
</script>