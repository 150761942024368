<script>
  import router from './index.js';
  export let path = '';
  export let name = '';
  export let klass = '';
  export let ariaLabel = '';

  function navigateTo() {
    router.navigate(path);
  }
</script>

<button class={klass} on:click={navigateTo} aria-label="{ariaLabel}">
    {#if name}
        {name}
    {:else}
        <slot></slot>
    {/if}
</button>