//import About from '../views/About.svelte';
//import Home from '../views/Home.svelte';

const routes = {
  '/': null,
  '/mission': 'mission',
  '/services': 'services',
  '/team': 'team',
};

export default routes;