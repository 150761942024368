<script>
    import { slide } from 'svelte/transition'
    import RouterLink from './Components/Router/RouterLink.svelte'
    import RouterButton from './Components/Router/RouterButton.svelte'
    import Router from './Components/Router/Router.svelte'
    import RouterScrollingOutlet from './Components/Router/RouterScrollingOutlet.svelte'
    import { flip } from 'svelte/animate';
    import { fly, fade } from 'svelte/transition';
    import { quintOut } from 'svelte/easing';

    export let name;

    let y;
    let isMenuActive = false;
    let isNeedsExpanded = false;
    let isAnimating = false;
    let answers = [
        'We offer a tailored approach, listening to <span class="tag apk-is-translucid  has-text-white is-rounded is-medium has-text-weight-semibold">your needs</span> and unique situation to support you in your <span class="tag apk-is-translucid  has-text-white is-rounded is-medium has-text-weight-semibold">strategic choices</span>.',
        'We empower you with the right tools and solutions to <span class="tag apk-is-translucid  has-text-white is-rounded is-medium has-text-weight-semibold">deliver value</span> through the appropriate channels.',
        'We accompany you in that journey, from the birth of an idea, to the delivery of a <span class="tag apk-is-translucid  has-text-white is-rounded is-medium has-text-weight-semibold">high-end digital product.</span>'
    ]

    let needs = [{
        isShown: true,
        header: {
            start: 'You are starting with digital',
            middle: 'and you ask yourself',
            end: 'these questions'
        },
    }, {
        isShown: false,
        questions: [
            'Where do I start with digitalization?',
            'How can I automate processes?',
            'How to build a powerful website, or an engaging App?'
        ],
    }, {
        isShown: true,
        header: {
            start: 'You have a digital strategy or product',
            middle: 'and meet the',
            end: 'following challenges'
        },
    }, {
        isShown: false,
        questions: [
            'How to deliver value while maximizing my return on investment?',
            'What technical solution should I choose to scale up and grow my business?',
            'What governance do I need to build a long-lasting solution and maintain agility?'
        ],
    },{
        isShown: true,
        answers: answers,
    }]

    $: shownNeeds = needs.filter(n => n.isShown)
    
    function handleMenuClick() {
        isMenuActive = !isMenuActive;
    }

    function animationStarted() {
        isAnimating = true;
    }

    function animationEnded() {
        isAnimating = false;
    }

    function handleNeedClick(index) {
        if (isAnimating) {
            return;
        }

        isAnimating = true;

        let isExpanded = needs.filter(n => n.header && n.isShown).length !== 2;

        if (isExpanded) {
            // First, hide all questions.
            for (let i = 0; i < needs.length; i++) {
                if (needs[i].questions) {
                    needs[i].isShown = false
                }
            }

            // Then, show all headers.
            for (let i = 0; i < needs.length; i++) {
                if (needs[i].header) {
                    setTimeout(function () {
                        needs[i].isShown = true
                    }, 350);
                }
            }

            isNeedsExpanded = false
        } else {
            // First, hide other header.
            needs[(index * 2 + 2) % 4].isShown = false
            // Then, show related content.
            needs[(index * 2 + 1) % 4].isShown = true

            isNeedsExpanded = true
        }
    }

    /**
     * Waits for a given amount of time
     * @param {number} ms The time in milliseconds to wait before resuming execution
     * @example
     *  const someFunction = () => {
     *    // Waits for 1 second before resuming execution
     *    await sleep(1000)
     *  }
     */
    const sleep = ms => new Promise(resolve => requestAnimationFrame(() => setTimeout(resolve, ms)));

    /**
     * Generates a random number between `min` and `max`
     * @param {number} min The minimum value in range
     * @param {number} max The maximum value in range
     * @example
     * // Generates a random number between 10 and 20
     * rng(10, 20)
     */
    const rng = (min, max) => Math.floor(Math.random() * (max - min) + min);

    function typewriter(node, { interval = [50, 60, 80] }) {
        const valid = (
            node.childNodes.length === 1 &&
            node.childNodes[0].nodeType === Node.TEXT_NODE
        );

        if (!valid) {
            throw new Error(`This transition only works on elements with a single text node child`);
        }

        const text = node.textContent;
        let ticks = [];

        for (let i = 0; i < text.length; i++) {
            ticks.push(interval[rng(0, interval.length)] || interval);
        }

        const duration = ticks.reduce((acc, t) => acc + t, 0);
        const cumulatedTicks = ticks.reduce((acc, t) => acc.concat(acc[acc.length - 1] + t / duration), [0]);

        return {
            duration,
            easing: t => {
                const i = ~~(cumulatedTicks.length * t);
                return cumulatedTicks[i];
            },
            tick: t => {
                const i = ~~(text.length * t);
                requestAnimationFrame(function () {
                    node.textContent = text.slice(0, i);
                });
            }
        };
    }

    const typings = ['Softwares', 'Web Sites', 'Web Apps', 'Mobile Apps', 'User Experiences','Applications','PWAs','Digital Strategy'];
    let typingIndex = -1;
    let typing = false;

    const endTypewriterLoop = async () => {
        await sleep(5000);
        typing = false;
    }

    const startTypewriterLoop = async () => {
        await sleep(60);
        typingIndex = (typingIndex + 1) % typings.length;
        typing = typings[typingIndex];
    }

    setTimeout(function () {
        startTypewriterLoop();
    });
</script>

<style global lang="scss">
    @import "theme.scss";
    @import "main.scss";
</style>

<svelte:head>

<title>{name}</title>

<script defer src="https://use.fontawesome.com/releases/v5.3.1/js/all.js"></script>
</svelte:head>

<Router/>
<RouterScrollingOutlet/>

<div class="container">
    <div class="block">
        <nav class="navbar" role="navigation" aria-label="main navigation">
            <div class="navbar-brand">
                <a class="navbar-item" href="https://www.apeak.studio">
                <figure class="image is-64x64">
                    <img src="/images/logo/logo.svg" alt="logo">
                </figure>  
                </a>
                <a class="navbar-item" href="https://www.apeak.studio">
                    <div>
                        <span class="title is-4">Apeak </span><span class="subtitle is-4">Studio</span>
                    </div>
                </a>
                
                <a href="#blank" role="button" class="navbar-burger burger" class:is-active="{isMenuActive}" aria-label="menu" aria-expanded="false" data-target="navbar" on:click="{handleMenuClick}">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
            </div>
            <div id="navbar" class="navbar-menu" class:is-active="{isMenuActive}" transition:slide>
                <div class="navbar-end">
                    <RouterLink path="/services" name="Services" klass="navbar-item"></RouterLink>
                    <RouterLink path="/mission" name="Mission" klass="navbar-item"></RouterLink>
                    <RouterLink path="/team" name="Team" klass="navbar-item"></RouterLink>
                    <div class="navbar-item">
                        <a class="button is-rounded is-primary has-text-weight-bold" href="https://docs.google.com/forms/d/e/1FAIpQLSdvrcTf58-cRgAzwwdXOy-I4AwArnbISwDuPAw2cLnvB4K7Xg/viewform?usp=sf_link">Start your project</a>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>

<svelte:window bind:scrollY={y}/>

<div class="parallax-container">

    <div
        class="white"
        style="transform: translateY({y >= 0 ? -y * 0.275 : 0}px); opacity: {y > 900 ? 1 - ((y - 900) / (1200 - 900)) : 1}"
    ></div>

    <img
        style="transform: translateY({y >= 0 ? -y * 0.233 : 0}px); opacity: {y > 460 ? 1 - ((y - 460) / (550 - 460)) : 1}"
        src="/images/parallax/layer0.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer0.480w.png 480w,
        /images/parallax/resized/layer0.768w.png 768w,
        /images/parallax/resized/layer0.1024w.png 1024w,
        /images/parallax/resized/layer0.1216w.png 1216w,
        /images/parallax/resized/layer0.1408w.png 1408w,
        /images/parallax/layer0.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >

    <img
        style="transform: translateY({ y >= 0 ? -y * 0.283 : 0}px); opacity: {y > 1150 ? 1 - ((y - 1150) / (1300 - 1150)) : 1}"
        src="/images/parallax/layer1.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer1.480w.png 480w,
        /images/parallax/resized/layer1.768w.png 768w,
        /images/parallax/resized/layer1.1024w.png 1024w,
        /images/parallax/resized/layer1.1216w.png 1216w,
        /images/parallax/resized/layer1.1408w.png 1408w,
        /images/parallax/layer1.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >
    <img
        style="transform: translateY({y >= 0 ? -y * 0.283 : 0}px); opacity: {y > 1050 ? 1 - ((y - 1050) / (1400 - 1050)) : 1}"
        src="/images/parallax/layer2x.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer2x.480w.png 480w,
        /images/parallax/resized/layer2x.768w.png 768w,
        /images/parallax/resized/layer2x.1024w.png 1024w,
        /images/parallax/resized/layer2x.1216w.png 1216w,
        /images/parallax/resized/layer2x.1408w.png 1408w,
        /images/parallax/layer2x.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >

    <img
        style="transform: translateY({y >= 0 ? -y * 0.316 : 0}px)"
        src="/images/parallax/layer3+5.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer3+5.480w.png 480w,
        /images/parallax/resized/layer3+5.768w.png 768w,
        /images/parallax/resized/layer3+5.1024w.png 1024w,
        /images/parallax/resized/layer3+5.1216w.png 1216w,
        /images/parallax/resized/layer3+5.1408w.png 1408w,
        /images/parallax/layer3+5.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >

    <img
        style="transform: translateY({y >= 0 ? -y * 0.333 : 0}px)"
        src="/images/parallax/layer4+6.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer4+6.480w.png 480w,
        /images/parallax/resized/layer4+6.768w.png 768w,
        /images/parallax/resized/layer4+6.1024w.png 1024w,
        /images/parallax/resized/layer4+6.1216w.png 1216w,
        /images/parallax/resized/layer4+6.1408w.png 1408w,
        /images/parallax/layer4+6.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >
    
    <img
        style="transform: translateY({y >= 0 ? -y * 0.416 : 0}px)"
        src="/images/parallax/layer7.png"
        alt="mountain"
        srcset="
        /images/parallax/resized/layer7.480w.png 480w,
        /images/parallax/resized/layer7.768w.png 768w,
        /images/parallax/resized/layer7.1024w.png 1024w,
        /images/parallax/resized/layer7.1216w.png 1216w,
        /images/parallax/resized/layer7.1408w.png 1408w,
        /images/parallax/layer7.png 3840w"
        sizes="calc(100vh * (3840/2160))"
    >
</div>

<section class="section apk-background hero is-fullheight-with-navbar">
    <div>
        <h1 id="main-title-wrapper" class="container has-text-centered" style="transform: translateY({y >= 0 ? y * 0.7 : 0}px)">
        <p id="main-title" class="subtitle is-size-1-desktop is-size-2-tablet is-size-3-mobile has-text-centered has-text-weight-bold m-0">
        <span class="is-hidden-mobile">&nbsp;&nbsp;</span>
            Handcrafted
            <span class="cursor">
            {#if typing}
                <span class="typing" transition:typewriter on:introend={endTypewriterLoop} on:outroend={startTypewriterLoop}>{typing}</span>
            {/if}
            </span>
        </p>
        <p class="subtitle is-size-2-desktop is-size-3-tablet is-size-4-mobile has-text-centered m-0">
        elevating your business to new heights.
        </p>
        </h1>
    </div>
</section>

<div id="start-scroll" class="container has-text-centered" style="opacity: {1 - (y/50)}">
    <RouterButton path="/services" klass="button is-outlined is-inverted is-rounded is-primary apk-is-circled apk-is-borderless" ariaLabel="Go">
       <span class="icon">
        <i class="fas fa-chevron-down fa-lg"></i>
        </span>
    </RouterButton>
</div>

<section id="services" class="section hero is-fullheight apk-gradient1">
    <div class="hero-head">
        <div class="container has-text-centered has-text-white">
            <h2 class="section-title">
                <span class="subtitle is-2 is-size-3-mobile has-text-white">Y</span><span class="subtitle is-2 is-size-3-mobile has-text-white" >our&nbsp;</span><span class="title is-2 is-size-3-mobile has-text-white">Services</span>
            </h2>
            <span class="has-text-white">needs</span>
        </div>
    </div>
    <div class="hero-body px-0">
        <div class="container">
            {#each shownNeeds as need, index (need)}
                    <div animate:flip="{{delay: 120, duration: 500, easing: quintOut }}">
                        {#if need.header}
                        <h3 class="is-bold has-text-white big-title pt-5 mb-5"
                             on:click={() => handleNeedClick(index)}
                             in:fly="{{x:-300, delay: 450, duration: 400, easing: quintOut}}"
                             out:fly="{{x:-300, delay: 0, duration: 400, easing: quintOut}}"
                             on:introstart={animationStarted}
                             on:introend={animationEnded}
                             on:outtrostart={animationStarted}
                             on:outroend={animationEnded}
                        >
                            {need.header.start}
                            <p class="medium-title has-text-white mt-1">{need.header.middle} <a href="#/" on:click|preventDefault>{need.header.end}</a>
                                {#if shownNeeds[index + 1] && shownNeeds[index + 1].questions}
                                <button in:fade="{{ delay: 400, duration: 400, easing: quintOut }}" out:fade="{{ delay: 0, duration: 400, easing: quintOut }}" class="button is-primary is-outlined is-rounded is-inverted is-small apk-back apk-is-circled-small" aria-label="Expand">
                                    <i class="fas fa-times fa-lg"></i>
                                </button>
                                {:else}
                                <button in:fade="{{ delay: 400, duration: 400, easing: quintOut }}" out:fade="{{ delay: 0, duration: 400, easing: quintOut }}" class="button is-primary is-outlined is-rounded is-small apk-back apk-is-borderless apk-is-circled-small" aria-label="Collapse">
                                    <i class="fas fa-arrow-right fa-lg"></i>
                                </button>
                                {/if}
                            </p>
                        </h3>
                        {/if}
                        {#if need.questions}
                        <div
                            in:fly="{{x:-200, delay: 450, duration: 400, easing: quintOut}}"
                            out:fly="{{x:-200, delay: 0, duration: 400, easing: quintOut}}">
                                {#each need.questions as question, index}
                                <p class="question title is-4 has-text-white">
                                    {question}
                                </p>
                                {/each}
                        </div>
                        {/if}
                        {#if need.answers}
                        <div class="pt-6 mb-6">
                            {#each need.answers as answer, index}
                                <p class="answer content is-medium has-text-white mb-0 has-text-right">
                                    {@html answer}
                                </p>
                            {/each}
                        </div>
                        {/if}
                    </div>
            {/each}
        </div>
    </div>
</section>

<section id="consulting" class="section hero is-fullheight apk-gradient5">
    <div class="hero-body px-0">
        <div class="container">
            <div class="columns is-vcentered apk-is-hreversed">
                <div class="column is-half">
                    <figure class="image image-title">
                        <img
                            src="/images/consulting.png"
                            alt="consulting process"
                            srcset="
                            /images/resized/consulting.240w.png 240w,
                            /images/resized/consulting.480w.png 480w,
                            /images/resized/consulting.768w.png 768w,
                            /images/consulting.png 800w"
                            sizes="(max-width: 768px) 100vw, (min-width: 769px) 50vw"
                        >
                    </figure>
                </div>
                <div class="column is-half">
                    <h3 class="title is-3 is-size-4-mobile has-text-white">Digital consulting</h3>
                    <p class="content is-medium has-text-white">
                    Good project management and methodology is the backbone of any endeavour.
                    We bring in our backpack a various set of skills to analyze, structure, initiate and lead digital projects.
                    </p>
                    <p class="content is-medium has-text-white">
                    As advisors and partners, we accompany you through your whole project lifecycle, or act at a specific step where you need support, be it strategy definition, digital offering set-up, partner selection, or actual project management.
                    </p>
                    <p class="content is-medium has-text-white">
                    We enable you to take informed decisions, to achieve your goals and meet your ambitions.
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="mobile-apps" class="section hero is-fullheight apk-gradient6">
    <div class="hero-body px-0">
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column is-half">
                    <figure class="image image-title">
                        <img
                            src="/images/iphone-white.png"
                            alt="mobile"
                            srcset="
                            /images/resized/iphone-white.240w.png 240w,
                            /images/resized/iphone-white.480w.png 480w,
                            /images/resized/iphone-white.768w.png 768w,
                            /images/resized/iphone-white.1024w.png 1024w,
                            /images/resized/iphone-white.1216w.png 1216w,
                            /images/resized/iphone-white.1408w.png 1408w,
                            /images/iphone-white.png 1736w"
                            sizes="(max-width: 768px) 100vw, (min-width: 769px) 50vw"
                        >
                    </figure> 
                </div>
                <div class="column is-half">
                    <h3 class="title is-3 is-size-4-mobile has-text-white">Mobile Applications</h3>
                    <p class="content is-medium has-text-white">
                    Building native experience for iOS/Swift or Android/Kotlin is one of our core skills.
                    </p>
                    <p class="content is-medium has-text-white">
                    Either from scratch or out-sourcing an existing project, we deliver your product directly into the mobile app stores, going through specification, architecture, design and coding.
                    </p>
                    <div class="columns is-mobile mt-6">
                        <!-- USDZ Scale: 0.03 -->
                        <a rel="ar" href="/3d/droid.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image osystem2 osystem">
                                <img
                                    src="/images/droid2.png"
                                    alt="android"
                                    srcset="
                                    /images/resized/droid2.80w.png 80w,
                                    /images/resized/droid2.120w.png 120w,
                                    /images/resized/droid2.240w.png 240w,
                                    /images/resized/droid2.480w.png 480w,
                                    /images/resized/droid2.768w.png 768w,
                                    /images/droid2.png 1100w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <!-- USDZ Scale: 0.05 -->
                        <a rel="ar" href="/3d/apple.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image osystem1 osystem">
                                <img
                                    src="/images/apple2.png"
                                    alt="apple"
                                    srcset="
                                    /images/resized/apple2.80w.png 80w,
                                    /images/resized/apple2.120w.png 120w,
                                    /images/resized/apple2.240w.png 240w,
                                    /images/resized/apple2.480w.png 480w,
                                    /images/resized/apple2.768w.png 768w,
                                    /images/apple2.png 1080w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <div class="column"></div>
                    </div>
                    <p class="mt-6">
                        <a class="button is-rounded is-primary has-text-weight-bold" href="https://docs.google.com/forms/d/e/1FAIpQLSdvrcTf58-cRgAzwwdXOy-I4AwArnbISwDuPAw2cLnvB4K7Xg/viewform?usp=pp_url&entry.1000027=Mobile+application+%F0%9F%93%B1">Start your Mobile project</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="web-apps" class="section hero is-fullheight apk-gradient2">
    <div class="hero-body px-0">
        <div class="container">
            <div class="columns is-vcentered  apk-is-hreversed">
                <div class="column is-half">
                    <figure class="image image-title">
                        <img
                            src="/images/devices.png"
                            alt="web"
                            srcset="
                            /images/resized/devices.240w.png 240w,
                            /images/resized/devices.480w.png 480w,
                            /images/resized/devices.768w.png 768w,
                            /images/resized/devices.1024w.png 1024w,
                            /images/resized/devices.1216w.png 1216w,
                            /images/resized/devices.1408w.png 1408w,
                            /images/devices.png 2304w"
                            sizes="(max-width: 768px) 100vw, (min-width: 769px) 50vw"
                        >
                    </figure> 
                </div>
                <div class="column is-half">
                    <h3 class="title is-3 is-size-4-mobile has-text-white">Web Applications</h3>
                    <p class="content is-medium has-text-white">
                    We provide end-to-end integrated Web Apps built on top of the most stable and proven technologies.
                    </p>
                    <p class="content is-medium has-text-white">
                    We build what you need, be it a Progressive Web App, corporate Website, or any kind of platform backed by cloud-based services.
                    </p>
                    <p class="content is-medium has-text-white">
                    We support our clients in the specification process, acting as a nexus between the design and the technical implementation.
                    </p>
                    <div class="columns is-mobile mt-6">
                        <!-- USDZ Scale: 0.03 -->
                        <a rel="ar" href="/3d/chrome.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image wbrowser1 wbrowser">
                                <img
                                    src="/images/chrome2.png"
                                    alt="chrome"
                                    srcset="
                                    /images/resized/chrome2.80w.png 80w,
                                    /images/resized/chrome2.120w.png 120w,
                                    /images/resized/chrome2.240w.png 240w,
                                    /images/resized/chrome2.480w.png 480w,
                                    /images/resized/chrome2.768w.png 768w,
                                    /images/chrome2.png 1080w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <!-- USDZ Scale: 0.015 -->
                        <a rel="ar" href="/3d/safari.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image wbrowser2 wbrowser">
                                <img
                                    src="/images/safari2.png"
                                    alt="safari"
                                    srcset="
                                    /images/resized/safari2.80w.png 80w,
                                    /images/resized/safari2.120w.png 120w,
                                    /images/resized/safari2.240w.png 240w,
                                    /images/resized/safari2.480w.png 480w,
                                    /images/resized/safari2.768w.png 768w,
                                    /images/safari2.png 1320w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <!-- USDZ Scale: 0.02 -->
                        <a rel="ar" href="/3d/edge.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image wbrowser3 wbrowser">
                                <img
                                    src="/images/edge2.png"
                                    alt="edge"
                                    srcset="
                                    /images/resized/edge2.80w.png 80w,
                                    /images/resized/edge2.120w.png 120w,
                                    /images/resized/edge2.240w.png 240w,
                                    /images/resized/edge2.480w.png 480w,
                                    /images/resized/edge2.768w.png 768w,
                                    /images/edge2.png 1080w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <!-- USDZ Scale: 0.015 -->
                        <a rel="ar" href="/3d/firefox.usdz" class="column is-2 p-0 pl-2">
                            <figure class="image wbrowser4 wbrowser">
                                <img
                                    src="/images/firefox2.png"
                                    alt="firefox"
                                    srcset="
                                    /images/resized/firefox2.80w.png 80w,
                                    /images/resized/firefox2.120w.png 120w,
                                    /images/resized/firefox2.240w.png 240w,
                                    /images/resized/firefox2.480w.png 480w,
                                    /images/resized/firefox2.768w.png 768w,
                                    /images/firefox2.png 1120w"
                                    sizes="70px"
                                >
                            </figure>
                        </a>
                        <div class="column"></div>
                    </div>
                    <p class="mt-6">
                        <a class="button is-rounded is-primary has-text-weight-bold" href="https://docs.google.com/forms/d/e/1FAIpQLSdvrcTf58-cRgAzwwdXOy-I4AwArnbISwDuPAw2cLnvB4K7Xg/viewform?usp=pp_url&entry.1000027=Website+/+Web+App+%F0%9F%8C%8E">Start your Web project</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section class="section hero is-fullheight apk-gradient2">
    <div class="hero-head">
        <div class="container has-text-centered">
            <h2>
                <span class="subtitle is-2 is-size-3-mobile has-text-white">Our&nbsp;</span><span class="title is-2 is-size-3-mobile has-text-white">Track Record</span>
            </h2>
        </div>
    </div>
    <div class="hero-body">
        <div class="container">
            <div class="columns is-vcentered">
                <div class="column is-quarter">
                    <figure class="image">
                        <img src="/images/medgate.png" alt="mobile">
                    </figure> 
                </div>
                <div class="column is-quarter">
                    <figure class="image">
                        <img src="/images/atupri.png" alt="mobile">
                    </figure> 
                </div>
                <div class="column is-quarter">
                    <figure class="image">
                        <img src="/images/dassault.png" alt="mobile">
                    </figure> 
                </div>
                <div class="column is-quarter">
                    <figure class="image">
                        <img src="/images/nokia.png" alt="mobile">
                    </figure> 
                </div>
            </div>
        </div>
    </div>
</section> -->

<section id="mission" class="section hero is-fullheight apk-gradient3">
    <div class="hero-head">
        <div class="container has-text-centered has-text-white">
            <h2 class="section-title">
                <span class="subtitle is-2 is-size-3-mobile has-text-white">Y</span><span class="subtitle is-2 is-size-3-mobile has-text-white">our&nbsp;</span><span class="title is-2 is-size-3-mobile has-text-white">Mission</span>
            </h2>
            <span class="has-text-white">vision</span>
        </div>
    </div>
    <div class="hero-body px-0">
        <div class="container">
            <p class="content is-medium has-text-white">
                We design, develop and deliver <span class="tag apk-is-translucid is-rounded is-medium has-text-weight-semibold">first class</span> applications, with <span class="tag apk-is-translucid is-rounded is-medium has-text-weight-semibold">user experience</span> at the heart of our concern.
                This user-centric approach arises from a proven methodology, using <span class="tag apk-is-translucid is-rounded is-medium has-text-weight-semibold">robust technologies</span> and best coding practices. This, together with our magic touch, ensures a seamless experience, generating immersive feelings.
            </p>
            <p class="content is-medium has-text-white">
                We ban cheap software, strive to bring value to user communities and are committed to elevate the Web further.
                To do so, we collaborate with highly skilled <span class="tag apk-is-translucid is-rounded is-medium has-text-weight-semibold">independent developers</span> and talented creators, to the best of our client's profit and satisfaction.
            </p>
        </div>
    </div>
</section>

<section id="team" class="section hero is-fullheight apk-gradient4">
    <div class="hero-head">
        <div class="container has-text-centered">
            <h2 class="section-title">
                <span class="subtitle is-2 is-size-3-mobile has-text-white">Y</span><span class="subtitle is-2 is-size-3-mobile has-text-white">our&nbsp;</span><span class="title is-2 is-size-3-mobile has-text-white">Team</span>
            </h2>
            <span class="has-text-white">partners</span>
        </div>
    </div>
    <div class="hero-body px-0">
        <div class="container">
            <p class="content is-medium has-text-centered has-text-grey-lighter mb-6">
            Friends and associates, we have over 10 years of experience as Web professionals. We seek excellence and surround ourselves with a team of experts matching our work ethics. We cherish independency and modern management based on trust, transparent communication and flexibility.
            </p>
            <div class="columns is-centered">
                <div class="column is-half-desktop is-one-third-widescreen">
                    <div class="card apk-is-fullheight">
                        <div class="card-image has-text-centered">
                            <figure class="image is-256x256 is-inline-block mt-5">
                                <img
                                    src="/images/florian-memoji.png"
                                    alt="Florian Maffini"
                                    srcset="
                                    /images/resized/florian-memoji.120w.png 120w,
                                    /images/resized/florian-memoji.240w.png 240w,
                                    /images/florian-memoji.png 421w"
                                    sizes="256px"
                                >
                            </figure>
                        </div>
                        <div class="card-content has-text-centered">
                            <div>
                                <span class="title is-4 has-text-white">Florian</span> <span class="subtitle is-4 has-text-white">Maffini</span>
                                <a href="https://www.linkedin.com/in/florian-maffini/" class="ml-1">
                                    <figure class="image is-16x16 is-inline-block">
                                        <img src="/images/linkedin.svg" alt="Linkedin">
                                    </figure>
                                </a>
                            </div>
                            <p class="subtitle is-5 has-text-info">
                            Web and Mobile Software Engineering
                            </p>
                            <p class="is-5 has-text-grey-lighter">
                            He can build pretty much anything which integrates into the Web, given a large amount of coffee.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="column is-half-desktop is-one-third-widescreen">
                    <div class="card apk-is-fullheight">
                        <div class="card-image has-text-centered">
                            <figure class="image is-256x256 is-inline-block mt-5">
                                <img
                                    src="/images/alban-memoji.png"
                                    alt="Alban Le Vallois"
                                    srcset="
                                    /images/resized/alban-memoji.120w.png 120w,
                                    /images/resized/alban-memoji.240w.png 240w,
                                    /images/alban-memoji.png 421w"
                                    sizes="256px"
                                >
                            </figure>
                        </div>
                        <div class="card-content has-text-centered">
                            <div>
                                <span class="title is-4 has-text-white">Alban</span> <span class="subtitle is-4 has-text-white">Le Vallois</span>
                                <a href="https://www.linkedin.com/in/albanlevallois/" class="ml-1">
                                    <figure class="image is-16x16 is-inline-block">
                                        <img src="/images/linkedin.svg" alt="Linkedin">
                                    </figure>
                                </a>
                            </div>
                            <p class="subtitle is-5 has-text-info">
                            Digital Strategy and Project Management
                            </p>
                            <p class="is-5 has-text-grey-lighter">
                            He knows a bunch about digital project management and how companies must drive through the Internet. He is most probably in a call right now.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="content is-medium has-text-centered has-text-grey-lighter">
                Want to work with us ?
            </p>
            <p class="has-text-centered">
                <a class="button is-rounded is-primary has-text-weight-bold" href="mailto:contact@apeak.studio">Say hi!</a>
            </p>
        </div>
    </div>
</section>

<footer class="footer has-text-grey-light">
    <div class="container">
        <div class="columns">
            <div class="column">
                <p class="is-5 title has-text-grey-light">Menu</p>
                <p class="mb-1">
                    <RouterLink path="/services" name="Services" klass="is-light"></RouterLink>
                </p>
                <p class="mb-1">
                    <RouterLink path="/mission" name="Mission" klass="is-light"></RouterLink>
                </p>
                <p class="mb-1">
                    <RouterLink path="/team" name="Team" klass="is-light"></RouterLink>
                </p>
            </div>
            <div class="column">
                <p class="is-5 title has-text-grey-light">Contact us</p>
                <p class="mb-2"><a href="mailto:contact@apeak.studio">contact@apeak.studio</a></p>
                <p>
                Apeak Studio<br/>
                Rue de la cité 1<br/>
                1204 Genève<br/>
                </p>
            </div>
            <div class="column">
                <p class="is-5 title has-text-grey-light">Follow us</p>
                <p class="mb-1"><a href="https://www.linkedin.com/company/apeakstudio">LinkedIn</a></p>
                <p class="mb-1"><a href="https://www.twitter.com/apeakstudio">Twitter</a></p>
            </div>
        </div>
        <div class="content has-text-centered has-text-grey mt-6">
            <p>This website has been handcrafted with <a class="has-text-grey-light" href="https://svelte.dev">Svelte</a> and <a class="has-text-grey-light" href="https://bulma.io">Bulma</a>.</p>
        </div>
    </div>
</footer>

