<script>
  import router from './index.js';
  export let path = '';
  export let name = '';
  export let klass = '';
  export let active = false;

  function navigateTo() {
    router.navigate(path);
  }

  router.pageChanged(({ path: newPath }) => {
    active = path === newPath;
  });
</script>

<a href={path} class={klass} class:active on:click|preventDefault={navigateTo}>
    {#if name}
        {name}
    {:else}
        <slot></slot>
    {/if}
</a>