import firebase from 'firebase/app';
import 'firebase/analytics';

// Firebase Configuration.
var firebaseConfig = {
    apiKey: "AIzaSyCoahPLaY-RvU0VjDsRfwE0JVncR_ddYPw",
    authDomain: "apeak-studio.firebaseapp.com",
    projectId: "apeak-studio",
    storageBucket: "apeak-studio.appspot.com",
    messagingSenderId: "143759003670",
    appId: "1:143759003670:web:d1247e4292b4cab24e2537",
    measurementId: "G-2383THQS66"
};

// Initialize Firebase.
firebase.initializeApp(firebaseConfig);
firebase.analytics();